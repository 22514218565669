/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";

import { ErrorScreen, bootstrapApp } from "ui-kit";

import App from "./App";
import config from "./config";
import en from "./i18n/en.json";
import th from "./i18n/th.json";

const { DEBUG } = config;

bootstrapApp({
  App,
  ErrorScreen,
  messages: { en, th },
  querySelector: "#root",
  DEBUG,
});
