import React from "react";
import { LoadingScreen, SuspenseLayout as UiKitSuspenseLayout } from "ui-kit";

import AppTopBar from "../AppTopBar";

export default function SuspenseLayout() {
  return (
    <UiKitSuspenseLayout appBar={<AppTopBar />}>
      <LoadingScreen />
    </UiKitSuspenseLayout>
  );
}
