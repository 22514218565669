import type {
  ApolloConfig,
  DatadogConfig,
  EnvironmentConfig,
  LineConfig,
  MediaConfig,
} from "ui-kit";
import {
  ApolloSchema,
  DatadogSchema,
  EnvironmentSchema,
  LineSchema,
  MediaSchema,
  Yup,
  getApolloConfig,
  getDatadogConfig,
  getEnvironmentConfig,
  getLineConfig,
  getMediaConfig,
  validateConfig,
} from "ui-kit";

type AppConfig = EnvironmentConfig &
  ApolloConfig &
  MediaConfig &
  LineConfig &
  DatadogConfig;

const schema: Yup.SchemaOf<AppConfig> = Yup.object()
  .concat(EnvironmentSchema)
  .concat(ApolloSchema)
  .concat(MediaSchema)
  .concat(LineSchema)
  .concat(DatadogSchema);

const envConfig = getEnvironmentConfig(process.env);

export default validateConfig<AppConfig>(
  {
    name: "Onboarding App",
    env: process.env,
    config: {
      ...envConfig,
      ...getApolloConfig(process.env),
      ...getMediaConfig(process.env),
      ...getLineConfig(process.env),
      ...getDatadogConfig(process.env),
    },
    schema,
  },
  {
    log: envConfig.DEBUG,
  }
);
